import React, { } from "react";

//importing pages
import Home from "./pages/home/home";
import Iletisim from "./pages/iletisim/iletisim";
import IlanDetail from "./pages/home/ilandetail";

//React Router v6.10
import { Switch, Route, withRouter} from 'react-router-dom';

function App() {

  return (
    <>
        <Switch>
        <Route path="/ilandetail" exact component={IlanDetail} />
        <Route path="/iletisim" exact component={Iletisim} />
          <Route path="/" exact component={Home} />
        </Switch>
    </>

  );
}

export default withRouter(App);