import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import {Redirect} from 'react-router-dom'

import { getIlanService, getIlanBySemtService, getIlanDetailStart} from '../../actions/home/home';
//import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
//import { styled } from '@mui/material/styles';

import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';
import Loader from "../../components/loader/loader";



const Home = () => {

    const dispatch = useDispatch();

    const ilan = useSelector(state => state.HomeReducer.ilan);
    const loadingilan = useSelector(state => state.HomeReducer.loadingilan);
    const semt = useSelector(state => state.HomeReducer.semt);
    const ilandetailstatus = useSelector(state => state.HomeReducer.ilandetailstatus);


    useEffect(() => {
        dispatch(getIlanService())
    }, []);


    const handleHome = () => {
        dispatch(getIlanService())
    }

    const handleOnClick = (item) => {
        console.log(item)
       dispatch(getIlanDetailStart(item))
    }

    let IlanListVip = ilan.map((item, index) => {

        if (!item.vesikalet && item.show ) {
            return (
                <>
                    <div>
                        <img  onClick={()=> handleOnClick(item)} class="imgdimVip" src={item.url1} />
                    </div>

                </>
            )
        }

    });


    let IlanList = ilan.map((item, index) => {
        if (item.vesikalet && item.show ) {
        return (
            <>
                <div>
                    <img onClick={()=> handleOnClick(item)} class="imgdim" src={item.url1} />
                </div>

            </>
        )
        }

    });


    let semtList = semt.map((item, index) => {
        return (
            <a key={index} onClick={() => handleMenu(item)} href="#company">{item.name}</a>
        )
    })

    const handleMenu = (item) => {
        dispatch(getIlanBySemtService(item.id))
    }

    let redirect = null;

    if(ilandetailstatus){
        redirect = <Redirect to='/ilandetail' />
    }

    return (
        <div style={{ backgroundColor: '#1A2027', margin: 0, padding: 0, width: '100%' }}>
            {redirect}

            <Header show={true} />

            <div class="contVip">
                {loadingilan ? <Loader /> : IlanListVip}
            </div>

            <div class="cont">

                {loadingilan ? <Loader /> : IlanList}
            </div>
        </div>


    )
};

export default Home;