import React, { useEffect } from 'react';
import Header from '../../components/header/header';

import { useDispatch, useSelector } from "react-redux";
import { getIlanDetailCancel } from '../../actions/home/home';
import { Redirect } from 'react-router-dom';
import whatssapButton from '../../assets/img/WhatsAppButtonGreenSmall.png'
import '../../assets/css/detail.css';
import moment from 'moment';
import 'moment/locale/tr'
moment.locale('tr')

const IlanDetail = () => {

    

    const dispatch = useDispatch()

    const ilandetail = useSelector(state => state.HomeReducer.ilandetail);
    const ilanredirect = useSelector(state => state.HomeReducer.ilanredirect);

    useEffect(() => {
        dispatch(getIlanDetailCancel())
    }, []);


    useEffect(() => {
        const imgs = document.querySelectorAll(".img-select a");
        const imgBtns = [...imgs];
        let imgId = 1;

        imgBtns.forEach((imgItem) => {
            imgItem.addEventListener("click", (event) => {
                event.preventDefault();
                imgId = imgItem.dataset.id;
                slideImage();
            });
        });

        function slideImage() {
            const displayWidth = document.querySelector(".img-showcase img:first-child")
                .clientWidth;

            document.querySelector(".img-showcase").style.transform = `translateX(${-(imgId - 1) * displayWidth
                }px)`;
        }

        window.addEventListener("resize", slideImage);
    },[])


    let redirect = null;

    if (!ilanredirect) {
        redirect = <Redirect to='/' />
    }

    return (
        <div style={{}}>
            {redirect}
            <Header show={false} />

            {/* <div style={{ color: 'white', margin: 100 }}>
                <img src={ilandetail.url1} style={{ width: '50%', height: '50vh', textAlign: 'center' }} />
                <p style={{ color: 'white' }}> Bilgi :  {ilandetail.bilgi}</p>
                <p style={{ color: 'white' }}> {ilandetail.contactlieu}</p>

                <p style={{ color: 'white' }}> Adı: {ilandetail.name}</p>

                <p style={{ color: 'white' }}> Semt : {ilandetail.semt.name}</p>

                <p style={{ color: 'white' }}> Numara {ilandetail.whatssap}</p>

                <p style={{ color: 'white' }}> Yaş: {ilandetail.yas}</p>

                <div dangerouslySetInnerHTML={{ __html: `${ilandetail.ilandetail}` }}></div>
            </div> */}


            <div class="card-wrapper">
                <div class="card">

                    <div class="product-imgs">
                        <div class="img-display">
                            <div class="img-showcase">
                                <img src={ilandetail.url1} alt="image" />
                                <img src={ilandetail.url2} alt="image" />
                                <img src={ilandetail.url3} alt="image" />
                                <img src={ilandetail.url4} alt="image" />
                            </div>
                        </div>
                        <div class="img-select">
                            <div class="img-item">
                                <a href="#" data-id="1">
                                    <img src={ilandetail.url1} alt="shoe image" />
                                </a>
                            </div>
                            <div class="img-item">
                                <a href="#" data-id="2">
                                    <img src={ilandetail.url2} alt="shoe image" />
                                </a>
                            </div>
                            <div class="img-item">
                                <a href="#" data-id="3">
                                    <img src={ilandetail.url3} alt="shoe image" />
                                </a>
                            </div>
                            <div class="img-item">
                                <a href="#" data-id="4">
                                    <img src={ilandetail.url4} alt="shoe image" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="product-content" style={{color:'white'}}>
                        <h2 style={{color:'white'}}  class="product-title">{ilandetail.bilgi}</h2>
                        <a href="#" class="product-link">{ilandetail.contactlieu}</a>
                        

                        <div class="product-price">
                            <a href={`https://wa.me/${ilandetail.whatssap}?text=seninle%20bir%20gece%20geçirmek%20istiyorum'`}>
                            <img src={whatssapButton} style={{width:'50%'}}/>
                            </a>
                           
                        </div>

                        <div class="product-detail" style={{color:'white'}}>
                            <h2 style={{color:'white'}}>Açiklama: </h2>
                            <div dangerouslySetInnerHTML={{ __html: `${ilandetail.ilandetail}` }}></div>
                           
                            <ul>
                                <li>Yaş: <span>{ilandetail.yas}</span></li>
                                {/* <li>semt: <span>{ilandetail.semt.name}</span></li> */}
                                <li>Adı: <span>{ilandetail.name}</span></li>
                                <li>Tahri: <span>{moment(ilandetail.updatedAt, "YYYYMMDD").fromNow()}</span></li>
                        
                            </ul>
                        </div>

                       

                       
                    </div>
                </div>
            </div>

        </div>
    )
};
export default IlanDetail