
//IMPORT URL
import { GET_ILAN_URL, GET_ILAN_BY_SEMT_URL, GET_ILETISIM_URL} from "../../urls/home/home";

// IMPORT TYPES
import {
    GET_ILAN_START, GET_ILAN_SUCCESS, GET_ILAN_FAILED, GET_ILAN_BY_SEMT_START,
     GET_ILAN_BY_SEMT_SUCCESS, GET_ILAN_BY_SEMT_FAILED, GET_ILETISIM_START, GET_ILETISIM_SUCCESS, 
     GET_ILETISIM_FAILED, GET_ILAN_DETAIL, GET_ILAN_DETAIL_CANCEL
} from "../../types/home/home";



export const getIlanStart = () => {
    return {
        type: GET_ILAN_START
    }
}

export const getIlanSuccess = (ilan,semt) => {
    return {
        type: GET_ILAN_SUCCESS,
        ilan: ilan,
        semt:semt
    }
}

export const getIlanFail = (error) => {
    return {
        type: GET_ILAN_FAILED,
        error: error
    }
}

export const getIlanService = () => {
    return dispatch => {
        dispatch(getIlanStart())
        fetch(GET_ILAN_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log("===>>>>", response)
                if (response.responseStatus === 200) {
                    dispatch(getIlanSuccess(response.data.ilan, response.data.semt));
                }
                else {
                    dispatch(getIlanFail("error"))
                }
            })
            .catch(error => {
                dispatch(getIlanFail(error.message))
            })
    }
}

export const getIlanBySemtStart = () => {
    return {
        type: GET_ILAN_BY_SEMT_START
    }
}

export const getIlanBySemtSuccess = (ilan) => {
    return {
        type: GET_ILAN_BY_SEMT_SUCCESS,
        ilan: ilan
    }
}

export const getIlanBySemtFail = (error) => {
    return {
        type: GET_ILAN_BY_SEMT_FAILED,
        error: error
    }
}

export const getIlanBySemtService = (semtid) => {
    return dispatch => {
        dispatch(getIlanBySemtStart())
        fetch(GET_ILAN_BY_SEMT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "semtid": semtid
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log("===>>>>", response)
                if (response.responseStatus === 200) {
                    dispatch(getIlanBySemtSuccess(response.data.response));
                }
                else {
                    dispatch(getIlanBySemtFail("error"))
                }
            })
            .catch(error => {
                dispatch(getIlanBySemtFail(error.message))
            })
    }
}

export const getIletisimStart = () => {
    return {
        type: GET_ILETISIM_START
    }
}

export const getIletisimSuccess = (iletisim) => {
    return {
        type: GET_ILETISIM_SUCCESS,
        iletisim: iletisim
    }
}

export const getIletisimFail = (error) => {
    return {
        type: GET_ILETISIM_FAILED,
        error: error
    }
}

export const getIletisimService = () => {
    return dispatch => {
        dispatch(getIletisimStart())
        fetch(GET_ILETISIM_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log("===>>>>", response)
                if (response.responseStatus === 200) {
                    dispatch(getIletisimSuccess(response.data.response));
                }
                else {
                    dispatch(getIletisimFail("error"))
                }
            })
            .catch(error => {
                dispatch(getIletisimFail(error.message))
            })
    }
}

export const getIlanDetailStart = (ilandetail) => {
    return {
        type: GET_ILAN_DETAIL,
        ilandetail:ilandetail
    }
}

export const getIlanDetailCancel = () => {
    return {
        type: GET_ILAN_DETAIL_CANCEL
    }
}