import React, { } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { getIlanService, getIlanBySemtService } from '../../actions/home/home';
import { Link } from 'react-router-dom';
import Loader from "../../components/loader/loader";


const Header = (props) => {

    const dispatch = useDispatch();

   
    const loadingilan = useSelector(state => state.HomeReducer.loadingilan);
    const semt = useSelector(state => state.HomeReducer.semt);

    const handleHome = () => {
        dispatch(getIlanService())
    }

    let semtList = semt.map((item, index) => {
        return (
            <a key={index} onClick={() => handleMenu(item)} href="#company">{item.name}</a>
        )
    })

    const handleMenu = (item) => {
        dispatch(getIlanBySemtService(item.id))
    }


    return (
      
            <div class="navbar">
                <Link to='/'  onClick={handleHome}>ESC</Link>
                {
                    props.show ?
                    <div class="subnav">
                    <button class="subnavbtn">Semt <i class="fa fa-caret-down"></i></button>
                    <div class="subnav-content" style={{ width: '50%' }}>
                        {loadingilan ? <Loader /> : semtList}
                    </div>
                </div>
                : null
                }
                
                <Link to='/iletisim' href="#contact">İletişim</Link>
            </div>





       
    )
};

export default Header; 