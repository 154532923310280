export const GET_ILAN_START = "GET_ILAN_START";
export const GET_ILAN_SUCCESS = "GET_ILAN_SUCCESS";
export const GET_ILAN_FAILED = "GET_ILAN_FAILED";


export const GET_ILAN_BY_SEMT_START = "GET_ILAN_BY_SEMT_START";
export const GET_ILAN_BY_SEMT_SUCCESS = "GET_ILAN_BY_SEMT_SUCCESS";
export const GET_ILAN_BY_SEMT_FAILED = "GET_ILAN_BY_SEMT_FAILED";

export const GET_ILETISIM_START = "GET_ILETISIM_START";
export const GET_ILETISIM_SUCCESS = "GET_ILETISIM_SUCCESS";
export const GET_ILETISIM_FAILED = "GET_ILETISIM_FAILED";

export const GET_ILAN_DETAIL ="GET_ILAN_DETAIL";
export const GET_ILAN_DETAIL_CANCEL ="GET_ILAN_DETAIL_CANCEL";
