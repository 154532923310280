import React, { useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";

import { getIletisimService } from '../../actions/home/home';
import Header from '../../components/header/header';
import Loader from '../../components/loader/loader';


const Iletisim = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getIletisimService())
    }, []);

    const loadingiletisim = useSelector(state => state.HomeReducer.loadingiletisim);
    const iletisim = useSelector(state => state.HomeReducer.iletisim);

    return (
        <div style={{}}>
            <Header show={false} />
            {
                loadingiletisim ? <Loader /> :  <div style={{color:'white', margin:50, fontSize:17}} dangerouslySetInnerHTML={{__html:`${iletisim.iletisimdetail}`}}></div>   
            }
        </div>
    )
}
export default Iletisim;