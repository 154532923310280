import React from 'react';
import ReactDOM from 'react-dom';
//simport './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';


// IMPORT DEPENDENCIES
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';


import './assets/css/main-style.css';

//  import './assets/css/zebra_tooltips.min.css'

//  import './assets/css/owl.carousel.min.css'

//  import './assets/css/responsive-style.css'

// IMPORT REDUCER
import HomeReducer from './reducers/home/home';

const RootReducer = combineReducers({
  HomeReducer:HomeReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//create store
const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
