import * as actionTypes from "../../types/home/home";

const initialState = {
  loadingilan: false,
  ilan: [],
  semt: [],
  error: null,
  iletisim: '',
  loadingiletisim: false,
  ilandetail: [],
  ilandetailstatus: false,
  ilanredirect: false
};

const ReducerHome = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_ILAN_START:
      return {
        ...state,
        loadingilan: true,
        error: null
      }

    case actionTypes.GET_ILAN_SUCCESS:
      return {
        ...state,
        loadingilan: false,
        ilan: action.ilan,
        semt: action.semt
      }

    case actionTypes.GET_ILAN_FAILED:
      return {
        ...state,
        error: action.error,
        loadingilan: false
      }

    case actionTypes.GET_ILAN_BY_SEMT_START:
      return {
        ...state,
        loadingilan: true,
        error: null
      }

    case actionTypes.GET_ILAN_BY_SEMT_SUCCESS:
      return {
        ...state,
        loadingilan: false,
        ilan: action.ilan
      }

    case actionTypes.GET_ILAN_BY_SEMT_FAILED:
      return {
        ...state,
        error: action.error,
        loadingilan: false
      }

    case actionTypes.GET_ILETISIM_START:
      return {
        ...state,
        loadingiletisim: true,
        error: null
      }

    case actionTypes.GET_ILETISIM_SUCCESS:
      return {
        ...state,
        loadingiletisim: false,
        iletisim: action.iletisim,
      }

    case actionTypes.GET_ILETISIM_FAILED:
      return {
        ...state,
        error: action.error,
        loadingiletisim: false
      }

    case actionTypes.GET_ILAN_DETAIL:
      return {
        ...state,
        ilandetail: action.ilandetail,
        ilandetailstatus: true,
        ilanredirect:true
      }

    case actionTypes.GET_ILAN_DETAIL_CANCEL:
      return {
        ...state,
        ilandetailstatus: false
      }


    default:
      return state;
  }
};

export default ReducerHome;
